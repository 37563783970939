<template>
    <div class="mt-1 flex flex-nowrap gap-x-3">
        <StoryblokImage
            v-for="(colorway, index) in props.colorways"
            :key="index"
            class="h-8 w-8 cursor-pointer rounded-full bg-white"
            :class="{
                'outline outline-2 outline-offset-2 outline-grind':
                    selectedIndex === index,
                'hover:outline hover:outline-2 hover:outline-offset-2 hover:outline-grind-200':
                    selectedIndex !== index,
            }"
            :src="colorway.swatch_image.src || '/images/image-placeholder.svg'"
            :alt="
                colorway.swatch_image.alt
                    ? colorway.swatch_image.alt
                    : 'Product Image'
            "
            sizes="32px"
            @click="handleSwatchSelection(index)"
        />
        <div
            v-if="props.isColorCustomizable && props.colorways.length > 0"
            class="bg-color-wheel relative h-8 w-8 cursor-pointer rounded-full"
            :class="{
                'outline outline-2 outline-offset-2 outline-grind':
                    selectedIndex === 9999,
                'hover:outline hover:outline-2 hover:outline-offset-2 hover:outline-grind-200':
                    selectedIndex !== 9999,
            }"
            @click="handleSwatchSelection(9999)"
        >
            <div
                class="absolute inset-2 flex items-center justify-center rounded-full bg-grind-100"
            >
                <Icon name="plus" class="text-xs"></Icon>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import StoryblokImage from '~/components/StoryblokImage.vue';
const props = defineProps<{
    colorways: array;
    isColorCustomizable: boolean;
}>();

const selectedIndex = ref<number>(0);

const emit = defineEmits<{
    (event: 'update-theme-image', index: number): void;
}>();

const handleSwatchSelection = (index: number) => {
    selectedIndex.value = index;
    emit('update-theme-image', index);
};
</script>
